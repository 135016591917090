.ant-tabs {
  > .ant-tabs-nav {
    margin-bottom: 32px;
  }

  &-tab {
    padding: 6px 16px;
    margin-left: 8px;
    margin-right: 8px;
  }

  &-tab .ant-tabs-tab-btn {
    font-size: 20px;
    line-height: 1.2;
    color: $c_text;
    transition: all 0.2s;

    &:hover {
      color: $c_deep_pink;
    }
  }

  &-ink-bar {
    background-color: $c_deep_pink;
    height: 1px;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $c_deep_pink;
  }
}
