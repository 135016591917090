.container {
  width: 1200px;
  @include content-container;
}

.row {
  $gutter: 12px;
  &--compact {
    $gutter: 6px;
  }

  display: flex;
  flex-wrap: wrap;
  margin-left: -1 * $gutter;
  margin-right: -1 * $gutter;

  > [class*="col"] {
    padding-left: $gutter;
    padding-right: $gutter;

    &.col-sticky {
      position: sticky;
      top: 20px;
      align-self: flex-start;
    }

    @media (max-width: 767px) {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      min-width: 375px;
    }
    @media (max-width: 480px) {
      min-width: 100%;
    }
  }

  &.no-gutters {
    margin-left: 0;
    margin-right: 0;

    > [class*="col"] {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 1px;

  @media (min-width: 768px) {
    &-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    &-8 {
      flex: 0 0 calc(200%/3);
      max-width: calc(200%/3);
    }

    &-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &-4 {
      flex: 0 0 calc(100%/3);
      max-width: calc(100%/3);
    }

    &-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}
