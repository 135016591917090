.ml-auto {
  margin-left: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-10 {
  margin-top: 10px !important;
}
