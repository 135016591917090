.ant-upload {
  &.ant-upload-drag {
    border: 2px dashed $c_primary;
    border-radius: 3px;
    min-height: 115px;
    display: flex;

    .ant-upload-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
    }

    .ant-form-item & {
      background-color: #fff;

      &:focus,
      &:hover {
        border-color: $c_text;
      }
    }

    label {
      font-size: 18px;
      color: $c_text;

      .form-item--warning & {
        color: $c_orange;
      }
    }

    p.ant-upload-text {
      margin: 0;
      font-size: 18px;
      line-height: 30px;
      color: $c_hint;

      .text-primary {
        color: $c_text;
      }

      .text-link {
        color: $c_primary;
      }
    }
  }
}

.ant-upload-list {
  display: flex;
  flex-wrap: wrap;

  &-picture {
    margin-left: -6px;
    margin-right: -6px;
    padding: 6px 0;
    &-container {
      min-width: 1px;
    }
    
    .ant-upload-list-item {
      border: 0;
      padding: 6px 16px;
      height: 41px;
      background-color: $c_light_blue;
      border-radius: 100px;
      margin: 6px;
      font-size: 16px;
      color: $c_primary;

      &:hover {
        background-color: $c_light_blue;
      }

      &-name {
        line-height: 30px;
        margin: 0;
        padding-left: 30px;
        padding-right: 24px;
      }

      &-uploading {
        .ant-upload-list-item-name {
          line-height: 30px;
        }
      }

      &-image {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      }

      &-thumbnail {
        width: 20px;
        height: 20px;
        top: 11px;
        left: 14px;

        .anticon {
          vertical-align: top;
          font-size: 20px;
        }
      }

      &-card-actions {
        top: 12px;
        right: 8px;
      }

      .ant-btn {
        background-color: transparent;
        color: $c_primary;

        .anticon {
          color: currentColor;
        }

        &:hover {
          color: $c_text;
        }
      }

      &-progress {
        bottom: -4px;
        padding: 0 30px;

        .ant-progress-bg {
          background-color: $c_primary;
        }
      }
    }
  }
}
