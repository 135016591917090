.ant-form {
  &-item {
    margin-bottom: 32px;

    @include queryMax(767px) {
      margin-bottom: 16px;
    }

    &-label {
      .ant-form-vertical & {
        padding-bottom: 6px;

        .ant-drawer &,
        .ant-popover & {
          padding-bottom: 4px;
        }
      }

      > label {
        font-size: $f_small;
        line-height: 1.4;
        color: $c_text;

        .ant-drawer &,
        .ant-popover & {
          font-size: $f_xsmall;
        }

        @include queryMax(767px) {
          font-size: $f_xsmall;
        }
      }
    }

    .form-item--suffix {
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      color: $c_description;
      position: absolute;
      right: 0;
      top: -23px;
    }

    &-control-input {
      min-height: 45px;

      & .ant-picker-clear {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg path {
          stroke: $c_text;
        }
      }

      .ant-drawer &,
      .ant-popover & {
        min-height: 37px;
      }
    }
  }

  .ant-btn {
    min-width: auto;

    & + .ant-btn {
      margin-left: 40px;
    }
  }

  .ant-divider.ant-divider-horizontal {
    margin: 0 0 32px 0;
  }
  .ant-row {
    .scoring {
      margin-bottom: 20px;
    }
  }

  .ant-form-item .ant-form-item-label,
  .ant-form-item .ant-form-item-control {
    @include queryMax(575px){
      flex: 0 0 auto;
      width: 100%;
    }
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-multiple .ant-select-selector,
.ant-input-affix-wrapper,
.ant-picker,
.ant-input-number,
.ant-form-item-control-input-content .ant-input-group > .ant-input,
.ant-form-item-control-input-content > .ant-input,
.ant-form-item-control-input-content [class*="col-"] .ant-input {
  padding: 7px 24px 6px;
  border-color: $c_light_grey;
  border-radius: 3px;
  font-size: 18px;
  line-height: 30px;
  font-weight: normal;
  color: $c_text;
  min-height: 45px;

  @include placeholder {
    color: $c_hint;
  }

  @include queryMax(767px){
    font-size: $f_small;
  }

  &:not([readonly]):not(.readonly) {
    &:focus,
    &:hover {
      border-color: $c_text;
    }
  }

  input {
    font-size: 18px;
    line-height: 30px;
  }

  .ant-drawer &,
  .ant-popover & {
    font-size: $f_small;
    min-height: 37px;
    line-height: 22px;
    padding-left: 16px;
    padding-right: 16px;

    input {
      font-size: $f_small;
      line-height: 22px;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.readonly,
  &[readonly] {
    cursor: default;
    background-color: $c_ghost_white;

    &:focus {
      box-shadow: none;
    }
  }
}

.ant-form-item.ant-form-item-has-error {
  .ant-form-item-control-input-content .ant-input {
    border-color: #ff4d4f;
  }
}

.ant-form-item-control-input-content > .ant-input-textarea {
  textarea {
    resize: none;
    min-height: 115px;
    padding: 7px 24px 6px;
    font-size: 18px;
    line-height: 30px;

    &:focus,
    &:hover {
      border-color: $c_text;
    }
  }
}

textarea.ant-input {
  &[readonly] {
    resize: none;

    & + .ant-btn {
      display: none;
    }
  }
}

.textarea-field {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 14px;
  }

  .ant-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}

.ant-checkbox {
  &-wrapper {
    color: $c_text;
    margin: 8px 0;
  }

  &-inner {
    border-color: $c_text;
    border-radius: 2px;
  }

  &-checked {
    &::after {
      display: none;
    }
  }

  &-checked &-inner {
    background-color: $c_light_green;
    border-color: $c_text;
    &:after {
      border-color: $c_text;
    }
  }
  &-wrapper:hover &-checked &-inner,
  &-checked:hover &-inner {
    background-color: $c_light_green;
    border-color: $c_text;
  }
  &-wrapper:hover &-inner,
  &:hover &-inner,
  &-input:focus + &-inner {
    border-color: $c_text;
    color: $c_text;
  }
}

.ant-radio-group {
  padding: 3px;
  border: 1px solid $c_light_grey;
  border-radius: 3px;
  width: 100%;
  display: flex;
  min-height: 45px;
  .ant-radio-wrapper {
    color: $c_text;
    margin-bottom: 4px;

    .ant-radio-inner {
      border-color: $c_text;
    }

    .ant-radio-checked .ant-radio-inner::after {
      background-color: $c_text;
    }
  }

  .ant-radio-button-wrapper {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 1px;
    text-align: center;
    height: auto;
    background: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: transparent;
    font-size: 18px;
    color: $c_text;

    &:hover {
      color: $c_primary;
    }

    &:before {
      display: none;
    }
  }

  &-solid {
    background: #fff;
    .ant-radio-button-wrapper {
      border: none;
      position: relative;

      &:focus {
        outline: none;
      }

      & > span:not(.ant-radio-button) {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        svg {
          width: 16px;
          height: 16px;
          margin-right: 6px;
          // path: {
          //   fill: $c_text;
          // }
        }
      }

      &:not(.ant-radio-button-wrapper-checked):not(:last-child):after {
        content: ' ';
        position: absolute;
        right: 0;
        width: 1px;
        height: 25px;
        top: 6px;
        background-color: $c_light_grey;
      }

      &.ant-radio-button-wrapper-checked {
        color: #fff;
        background-color: $c_primary;
        border-right-color: transparent;

        // & > span:not(.ant-radio-button) svg path {
        //   fill: #fff;
        // }

        &:not(.ant-radio-button-wrapper-disabled):focus-within {
          box-shadow: none;
        }

        &:not(.ant-radio-button-wrapper-disabled):hover {
          background-color: $c_persian_blue;
        }
      }
    }
  }

  .ant-drawer &,
  .ant-popover & {
    min-height: 37px;

    .ant-radio-button-wrapper {
      font-size: $f_small;
      line-height: 29px;
    }
  }
}

.ant-picker {
  width: 100%;

  &-suffix {
    color: $c_text;
  }
}

.ant-input-number {
  width: 100%;
}

.ant-input-suffix {
  font-size: 14px;
}

.ant-input-password-icon {
  color: $c_primary;
  &:hover {
    color: $c_persian_blue;
  }
}

.form-actions {
  margin-top: 60px;
  &:last-child {
    margin-bottom: 0;
  }

  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;

    @include queryMax(479px) {
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 20px;

      .ant-btn {
        width: 48%;

        & + .ant-btn {
          margin: 0 !important;

          &:last-child {
            order: -1;
            width: 100%;
            margin: 0 0 20px !important;
          }
        }
      }
    }
  }
}
