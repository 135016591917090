.container-form .ant-card {
  border-color: $c_light_grey;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  &-body {
    padding: 40px 48px;

    @include query-1199 {
      padding: 20px 24px;
    }
  }
}
