.actions-group {
  background-color: #fff;
  border: 1px solid $c_light_grey;
  border-radius: 14px;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 16px 0;

  &__item {
    flex: 1;
    padding: 0;
    font-size: 14px;
    font-weight: 600;

    &.ant-btn:not([disabled]):hover > span {
      text-decoration: underline;
    }
    
    &:not(:last-child) {
      &:after {
        position: absolute;
        right: 0;
        color: $c_light_grey;
        content: "|";
      }
    }
    &--default, &--default.ant-btn:hover {
      color: $c_primary!important;
    }
    &--danger, &--danger.ant-btn:hover {
      color: $c_red!important;
    }
    &--success, &--success.ant-btn:hover {
      color: $c_green!important;
    }
  }
}
