@mixin placeholder() {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    @content;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    @content;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    @content;
  }
  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }
}

@mixin text-overflow($line-clamp: 1) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $line-clamp;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: normal;
}

@mixin content-container() {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@mixin queryMax($max) {
  @media (max-width: $max) {
    @content;
  }
}

@mixin queryMin($min) {
  @media (min-width: $min) {
    @content;
  }
}

@mixin queryMinMax($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin query-1199() {
  @include queryMax(1199px) {
    @content;
  }
}

@mixin query-991() {
  @include queryMax(991px) {
    @content;
  }
}

@mixin query-767() {
  @include queryMax(767px) {
    @content;
  }
}

@mixin query-ie() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}


@mixin container {
  width: 1270px;
  max-width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@mixin heading {
  font-family: $ff_heading;
  font-weight: 600;
  text-align: center;
  font-size: 38px;
  margin-bottom: 70px;

  :global(body.hub71-theme) & {
    font-weight: normal;
  }

  & span {
    color: $c_deep_pink;
  }

  @media (max-width: 1199px) {
    font-size: $f_regular * 1.5;
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    font-size: $f_regular;
    margin-bottom: 24px;
  }
}

@mixin heading-h1 {
  font-family: $ff_heading;
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  margin-bottom: 40px;

  & span {
    color: $c_deep_pink;
  }

  @media (max-width: 1439px) {
    font-size: 50px;
    line-height: 1.2;
  }

  @media (max-width: 1199px) {
    font-size: 40px;
    line-height: 1.2;
  }

  @media (max-width: 767px) {
    font-size: $f_regular;
    margin-bottom: 24px;
    text-align: center;
  }
}
