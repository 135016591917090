.ant-modal-confirm {
  .ant-modal-close {
    top: 58px;
    right: 58px;
  }

  .ant-modal-close-x {
    width: 24px;
    height: 24px;
    line-height: 1;
  }

  .ant-modal-content {
    box-shadow: 0 10px 20px rgba(108, 109, 190, 0.3);
    border-radius: 10px;
    overflow: hidden;
  }

  @media (max-width: 768px) {
    .ant-modal-close {
      top: 30px;
      right: 20px;
    }
  }

  .ant-modal-body {
    padding: 40px 58px;
    color: $c_text;

    @media (max-width: 768px) {
      padding: 24px 16px;
    }
  }

  div.ant-typography {
    font-size: 18px;
  }

  .ant-modal-confirm-title {
    font-size: 18px;
  }
}