.ant-collapse {
  background-color: transparent;
  border: 0;
  border-radius: 0;

  &-header {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    color: $c_text;
    border-radius: 0;

    .ant-collapse-extra {
      color: $c_description;
      margin-left: auto;
      font-size: 14px;
      flex-basis: 0;
      flex-grow: 1;
      min-width: 1px;
      padding-left: 24px;
      padding-top: 4px;
      text-align: right;

      @include text-overflow();
    }
  }

  > .ant-collapse-item {
    border-bottom-color: $c_light_grey;
    margin-bottom: -1px;

    &:first-child {
      border-top: 1px solid $c_light_grey;
    }

    &:last-child {
      border-radius: 0;

      > .ant-collapse-header {
        border-radius: 0;
      }

      .ant-collapse-content {
        .ant-collapse-content-box {
          padding-bottom: 0;
        }
      }
    }

    .ant-collapse-content {
      border-bottom-color: $c_light_grey;
      border-radius: 0;
    }

    > .ant-collapse-header {
      background-color: transparent;

      &:hover {
        background-color: $c_light_grey;
      }

      > svg:not(.ant-collapse-arrow) {
        margin-right: 16px;
      }

      .ant-collapse-arrow {
        transform: none;
        width: 16px;
        height: 16px;
        margin-top: -8px;
        top: 50%;
        transition: all 0.2s;
        padding: 0;
      }
    }

    &.ant-collapse-item-active {
      border-top-color: transparent;
      > .ant-collapse-header {
        background-color: $c_light_blue;

        .ant-collapse-arrow {
          transform: rotate(180deg);
        }
      }

      .ant-collapse-content {
        border-top-color: transparent;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .ant-card & {
    margin: 0 -48px;

    > .ant-collapse-item > .ant-collapse-header,
    .ant-collapse-content > .ant-collapse-content-box {
      padding-left: 48px;
      padding-right: 48px;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding-top: 40px;
      padding-bottom: 8px;
    }

    &-icon-position-right {
      > .ant-collapse-item > .ant-collapse-header {
        padding-right: 96px;

        .ant-collapse-arrow {
          right: 48px;
        }
      }
    }

    @include query-1199 {
      margin: 0 -24px;

      > .ant-collapse-item > .ant-collapse-header,
      .ant-collapse-content > .ant-collapse-content-box {
        padding-left: 24px;
        padding-right: 24px;
      }

      &-icon-position-right {
        > .ant-collapse-item > .ant-collapse-header {
          padding-right: 72px;

          .ant-collapse-arrow {
            right: 24px;
          }
        }
      }
    }
  }

  .ant-tabs & {
    > .ant-collapse-item {
      border: 0;

      > .ant-collapse-header {
        border: 0;
        padding: 6px 0;
        z-index: 1;
        font-size: 24px;
        line-height: 1.5;
        color: $c_text;

        &:before {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100vw;
          background-color: $c_light_blue;
          z-index: -1;
          pointer-events: none;
        }
      }
    }

    .ant-collapse-content {
      background-color: transparent;
      border: 0;

      > .ant-collapse-content-box {
        padding: 40px 0;
      }
    }

    &-icon-position-right {
      > .ant-collapse-item > .ant-collapse-header {
        padding-right: 48px;

        .ant-collapse-arrow {
          right: 0;
        }
      }
    }
  }
}

.ant-tabs {
  overflow: visible;

  .select-autocomplete {
    width: 296px;
    margin: 12px 0 40px 0;
  }
}
