.ant-popover {
  max-width: 340px;

  &.job-status-popover {
    width: 400px;
    max-width: 400px;

    .ant-collapse-item {
      border: none;

      &-active {
        .ant-collapse-header {
          background-color: transparent;
        }
      }

      .ant-collapse-header {
        color: $c_description;
        font-size: 12px;
        padding: 12px 0;

        &:before {
          display: none;
        }

        .ant-collapse-extra {
          font-size: 12px;
          color: $c_hint;
        }

        svg.ant-collapse-arrow {
          width: 10px;
          margin-top: 0;
        }

        &:hover {
          background-color: transparent;
        }
      }

      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }

  &.confirm-popover {
    max-width: 250px;
    .ant-popover-arrow,
    .ant-popover-inner {
      margin-top: 5px;
    }
    .ant-popover-arrow {
      left: 12px!important;
    }
    .ant-popover-inner {
      transform: translate(-32px);
    }

    .ant-popover-message-title {
      padding-left: 0;
      font-size: 18px;
      line-height: 1.4;
    }

    .ant-popover-message {
      padding-top: 0;
      margin-bottom: 12px;
    }

    .ant-popover-buttons {
      margin-bottom: 0;
      .ant-btn {
        margin-left: 8px;
      }
    }
  }

  &.confirm-popover-updates  {
    .ant-popover-arrow,
    .ant-popover-inner {
      margin-top: 5px;
    }
    .ant-popover-arrow {
      left: 12px!important;
    }
    .ant-popover-inner {
      transform: translate(-32px);
    }
  }

  &.reports-popover {
    min-width: calc(50% - 10px);
  }

  &--footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    .ant-btn {
      margin: 0!important;
    }
  }

  &.reports-popover {
    min-width: calc(50% - 10px);
  }

  &--footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    .ant-btn {
      margin: 0!important;
    }
  }

  &-title {
    padding: 24px 24px 0 24px;
    font-size: 18px;
    border-bottom: none;
  }

  &-inner {
    box-shadow: 0px 10px 20px rgba(108, 109, 190, 0.3);
    border-radius: 20px;

    &-content {
      font-size: 14px;
      color: $c_text;
      padding: 24px;
    }
  }

  &-arrow {
    display: none;
  }

  &.with-arrow {
    .ant-popover-inner {
      border: 1px solid var(--c_light_grey);
      box-shadow: none;
    }

    .ant-popover-arrow {
      display: block;
      left: 20%;
      width: 25px;
      height: 25px;
      transform: translateX(-12px) rotate(45deg);
      background-color: #fff;
      border-color: var(--c_light_grey) !important;
      z-index: 1;
    }

    &.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
    &.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
      left: 15px;
      right: auto;
    }

    &.ant-popover-placement {
      &-bottomLeft,
      &-topLeft {
        > .ant-popover-content > .ant-popover-arrow {
          left: 15px;
          right: auto;
        }
      }

      &-bottomRight,
      &-topRight {
        > .ant-popover-content > .ant-popover-arrow {
          left: auto;
          right: 15px
        }
      }

      &-bottom,
      &-bottomLeft,
      &-bottomRight {
        > .ant-popover-content > .ant-popover-arrow {
          border-width: 1px 0 0 1px;
          box-shadow: none;
          top: -2px;
        }
      }

      &-top,
      &-topLeft,
      &-topRight {
        > .ant-popover-content > .ant-popover-arrow {
          border-width: 0 1px 1px 0;
          box-shadow: none;
          bottom: -2px;
        }
      }

      &-bottom,
      &-top {
        > .ant-popover-content > .ant-popover-arrow {
          left: 50%;
          right: auto;
        }
      }

      &-bottom,
      &-top {
        > .ant-popover-content > .ant-popover-arrow {
          left: 50%;
          right: auto;
        }
      }
    }
  }

  &.checklist-popover {
    max-width: 385px;

    form.ant-form {
      label.ant-checkbox-wrapper {
        margin-left: 0;
      }

      button {
        margin-left: 0;
      }
    }
  }
}
