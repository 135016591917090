.container-form {
  width: 928px;
  @include content-container;

  h1 {
    font-weight: normal;
    color: $c_text;
    margin-bottom: 8px;
  }
}

.form-dropdown {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 30px;
  gap: 5px;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-dropdown-trigger {
    font-size: 18px;
    line-height: 30px;
    color: $c_primary;
    text-decoration: underline;
  }

  .ant-form-item-control-input {
    min-height: auto;
  }
}

.deletable-input {
  label {
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}
