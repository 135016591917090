@mixin hover() {
  &:not(:disabled) {
    &:hover, &:focus, &:active {
      @content;
    }
  }
}

@mixin btnColor($color, $colorHover) {
  background-color: $color;
  border-color: $color;
  color: #fff;

  @include hover {
    background-color: $colorHover;
    border-color: $colorHover;
    color: #fff;
  }
}

@mixin btnColorGhost($color, $colorHover) {
  &:not(:disabled).ant-btn-ghost {
    background-color: transparent;
    border-color: $color;
    color: $color;

    @include hover {
      background-color: transparent;
      border-color: $colorHover;
      color: $colorHover;
    }
  }
}

a.ant-btn,
button.ant-btn {
  font-size: 14px;
  line-height: 17px;
  padding: 7px 16px !important;
  height: auto;
  border-radius: 6px;
  font-weight: 600;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

  svg {
    width: 16px;
    height: 16px;
    vertical-align: top;
  }

  body.hub71-theme & {
    font-family: $ff_heading;
  }

  .ant-btn-loading-icon {
    > span {
      vertical-align: top;
    }
  }

  &-sm {
    padding: 5px 10px !important;
    font-size: 12px;
    line-height: 120%;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  &-lg {
    font-size: 18px;
    line-height: 25px;

    svg {
      margin-top: 5px;
    }
  }

  @include btnColor($c_primary, $c_persian_blue);

  &-primary {
    @include btnColor($c_primary, $c_persian_blue);
  }

  &-ghost {
    @include btnColorGhost($c_primary, $c_persian_blue);
  }

  &-success {
    @include btnColor($c_green, $c_jet_stream);
    @include btnColorGhost($c_green, $c_jet_stream);
  }

  &-dangerous {
    @include btnColor($c_red, $c_salmon);
    @include btnColorGhost($c_red, $c_salmon);
  }

  &-link {
    color: $c_primary;
    padding: 0 10px !important;
    background: none;
    border: none;
    min-width: auto;
    box-shadow: none;

    @include hover {
      background: none;
      color: $c_persian_blue;
    }

    &.ant-btn-icon-only {
      padding: 0 !important;
      line-height: 1;
      width: auto;
      height: auto;
    }
  }

  &-text {
    &, &:hover, &:not(:disabled):hover, &:active, &:focus {
      color: $c_primary;
      background: transparent;
      border: none;
      min-width: auto;
      box-shadow: none;
    }
  }

  &-icon-only {
    height: 33px;
    width: 33px;
    padding: 0 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:not(:hover) {
      border-color: $c_light_grey !important;
    }

    svg {
      margin: 0;
    }

    &.ant-btn-sm {
      border-radius: 6px;
      height: 26px;
      width: 26px;
    }

    &.ant-btn-lg {
      border-radius: 6px;
      height: 41px;
      width: 41px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &.icon-right {
    svg {
      margin-left: 8px;
    }
  }

  &.icon-left {
    svg {
      vertical-align: top;
      margin-right: 8px;
    }
  }
}
