html,
body {
  padding: 0;
  margin: 0;
  font-family: $ff_text;
  color: $c_text;
  font-size: $f_regular;
  font-weight: 400;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}

body.hub71-theme * {
  border-radius: 0 !important;
}

a {
  color: $c_primary;
  text-decoration: none;
  &:hover {
    color: $c_persian_blue;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  font-family: $ff_heading;
}

.ant-layout {
  background-color: #fff;
}

.ant-typography,
.ant-card {
  color: $c_text;
}

.app-store-links {
  margin: 0 24px;
  white-space: nowrap;

  img {
    height: 40px;
  }
  a:first-child {
    margin-right: 32px;
  }

  @media (max-width: 1280px) {
    margin: 0 10px;

    a:first-child {
      margin-right: 16px;
    }
  }

  @media (max-width: 1024px) {
    margin: 0 10px;
  }
}

.ant-message {
  font-family: $ff_text;
  font-size: 14px;
  color: $c_text;
}

.page-heading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 40px;
  gap: 24px;


  &:not(:first-child) {
    margin-top: 16px;
  }

  h1 {
    font-size: 38px;
    font-weight: normal;
    line-height: 1.2;
    margin: 0 auto 0 0;
    color: $c_text;

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  a:not(.ant-btn) {
    margin-left: 5px;
    color: $c_primary!important;

    &:hover,
    &:active,
    &:focus {
      color: $c_persian_blue!important;
      text-decoration: underline;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: auto;
    margin-left: auto;

    svg {
      width: 16px;
      height: 16px;
      margin-left: 16px;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-radio-group .ant-radio-button-wrapper {
    min-width: 160px;
  }

  @include queryMax(479px) {
    h1 {
      font-size: 28px;
      margin-right: 16px;
    }

    &__btn {
      margin: 20px 0 0;
    }
  }
}

.page-filters {
  .page-heading & {
    display: inline-flex;
    flex: 1 auto;
    justify-content: flex-end;
    align-items: center;
    column-gap: 24px;
    .ant-form-item {
      margin-bottom: 0;
    }
  }

}

.ant-spin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
}

.icon--loading {
  animation: spin 1s infinite linear;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: white;

  .loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50% !important;
    border: 3px solid transparent;
    border-top-color: #3498db;
    animation: spin 2s linear infinite;

    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50% !important;
      border: 3px solid transparent;
      border-top-color: #e74c3c;
      animation: spin 3s linear infinite;
    }

    &:after {
      content: '';
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50% !important;
      border: 3px solid transparent;
      border-top-color: #f9c922;
      animation: spin 1.5s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
