@import "../variables";

.modal {
  &:global(.ant-modal) {
    padding: 30px 0;
  }

  & :global(.ant-modal-close) {
    top: 58px;
    right: 58px;
  }

  & :global(.ant-modal-close-x) {
    width: 24px;
    height: 24px;
    line-height: 1;
  }

  & :global(.ant-modal-content) {
    box-shadow: 0 10px 20px rgba(108, 109, 190, 0.3);
    border-radius: 10px;
    overflow: hidden;
  }

  @media (max-width: 768px) {
    & :global(.ant-modal-close) {
      top: 30px;
      right: 20px;
    }
  }

  & :global(.ant-modal-body) {
    padding: 40px 58px;
    color: $c_text;
    
    @media (max-width: 768px) {
      padding: 24px 16px;
    }
  }

  :global(div.ant-typography) {
    font-size: 18px;
  }


  .footer, :global(.modal-footer) {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;

    :global(.ant-btn) {
      min-width: auto;
    }

    & :global(.ant-btn + .ant-btn) {
      margin-left: 32px;
    }
  }
}

.title {
  font-size: 32px;
  line-height: 1.5;
  margin-top: 5px;
  margin-right: 25px;
  font-weight: normal;
  margin-bottom: 32px;

  & + a {
    display: block;
    margin-top: -12px;
    margin-bottom: 32px;
  }
}

.subheading {
  margin-top: -32px;
  margin-bottom: 32px;

  &, & :global(.ant-btn) {
    font-size: $f_small;
    line-height: 1.2;
  }

  
}

.edit_request {
  &__store_links {
    display: flex;
    justify-content: flex-end;
  }
}

.form_buttons {
  width: 100%;
  text-align: right;

  & :global(.ant-btn) {
    min-width: auto;
  }

  & :global(.ant-btn + .ant-btn) {
    margin-left: 32px;
  }
}
