.ant-dropdown {
  &.ant-dropdown-placement-bottomRight {
    margin-top: 8px;
  }

  &--fixed {
    position: fixed;
  }

  &-placement-top,
  &-placement-topLeft,
  &-placement-topRight {
    padding-bottom: 10px;
  }

  &-placement-bottom,
  &-placement-bottomLeft,
  &-placement-bottomRight {
    padding-bottom: 10px;
  }

  &-menu {
    padding: 0;
    background: #fff;
    border: 1px solid $c_light_grey;
    box-shadow: 0 10px 20px rgba(108, 109, 190, 0.3);
    border-radius: 6px;
    min-width: 200px !important;
    overflow: hidden;

    &-item {
      font-weight: 600;
      font-size: 14px;
      line-height: 1.2;
      padding: 12px 20px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 12px;
      }

      &-selected {
        color: inherit;
        background-color: $c_light_blue;
        pointer-events: none;
      }

      &:hover {
        background-color: $c_light_grey;
      }
    }
  }
}
