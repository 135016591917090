.jobs {
  .heading {
    max-width: 900px;
    text-align: center;
    margin: 40px auto;
    font-size: 38px;

    span {
      color: $c_deep_pink;
    }

    &-search {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ant-input-group {
        margin-bottom: 20px;
      }
    }
  }
  h3.ant-typography {
    margin-bottom: 24px;
  }
}

.job {
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 12px;
  height: calc(100% - 12px);

  &__location {
    display: flex;
    align-items: center;

    svg {
      margin-right: 6px;
    }
  }

  &__description,
  &__details,
  &__skills {
    margin-top: 24px;
  }

  &__info-item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 8px;
    }

    &__label {
      color: $c_description;
      margin-right: 16px;
    }
  }

  &__skills {
    display: flex;

    svg {
      flex: 0 0 24px;
      margin-right: 16px;
    }

    &__popover, .ant-typography {
      text-transform: capitalize;
    }
  }

  &__apply {
    margin-top: 32px;
    width: 100%;
  }

}
