@import "../variables";

.notification-item {
  border-width: 1px;
  border-style: solid;
  padding: 0;
  border-radius: 8px;

  .ant-notification-notice {
    &-with-icon {
      padding: 11px 24px 11px 71px;
    }

    &-message, &-description {
      margin-left: 0;
    }

    &-icon {
      height: 100%;
      top: 0;
      left: 0;
      margin: 0;
      width: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


  &--success {
    border-color: $c_green;

    .ant-notification-notice-icon {
      background-color: $c_green;
    }
  }
}
