.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  width: auto;
  text-align: left;
  color: $c_hint;
}

.ant-timeline-item-tail {
  border-left: 1px solid $c_persian_blue;
  left: calc(20% + 1px);
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(25% - 4px);
}
