.ant-slider {
  &-handle {
    width: 22px;
    height: 22px;
    background-color: $c_primary;
    border: 0;
    margin-top: -9px;
  }

  &-dot {
    background-color: $c_light_blue;
    border: 0;
  }

  &-dot-active {
    background-color: $c_primary;
  }

  &-mark {
    top: 20px;
  }

  &-rail {
    height: 2px;
    top: 5px;
    background-color: $c_light_blue;
  }

  &-track {
    background-color: $c_primary;
    height: 2px;
    top: 5px;
  }

  &:hover {
    .ant-slider-rail {
      background-color: $c_light_blue;
    }

    .ant-slider-track {
      background-color: $c_primary;
    }

    .ant-slider-handle {
      border-color: $c_primary;
    }
  }
}
