//colors
$c_text: var(--c_text);
$c_description: var(--c_description);
$c_hint: var(--c_hint);
$c_light_grey: var(--c_light_grey);
$c_ghost_white: var(--c_ghost_white);
$c_dark_violet: var(--c_dark_violet);
$c_primary: var(--c_primary);
$c_persian_blue: var(--c_persian_blue);
$c_violet: var(--c_violet);
$c_light_violet: var(--c_light_violet);
$c_deep_pink: var(--c_deep_pink);
$c_red: var(--c_red);
$c_light_red: var(--c_light_red);
$c_orange: var(--c_orange);
$c_light_orange: var(--c_light_orange);
$c_light_blue: var(--c_light_blue);
$c_green: var(--c_green);
$c_light_green: var(--c_light_green);
$c_jet_stream: var(--c_jet_stream);
$c_salmon: var(--c_salmon);
$c_pink: var(--c_pink);
$c_white: var(--c_white);
$c_linkedin: var(--c_linkedin);
$c_magnolia: var(--c_magnolia);

//font-size
$ff_heading: var(--ff_heading);
$ff_text: var(--ff_text);
$f_regular: 18px;
$f_small: 14px;
$f_xsmall: 12px;
