.ant-select-single:not(.ant-select-customize-input),
.ant-select-multiple {
  &:hover,
  &.ant-select-focused {
    &:not(.ant-select-disabled) .ant-select-selector {
      border-color: $c_text;
    }
  }
}

.ant-select-show-arrow .ant-select-arrow, .ant-select-allow-clear .ant-select-clear {
  width: 16px;
  height: 16px;
  font-size: 16px;
  right: 24px;
  svg path {
    stroke: $c_text;
  }

  .ant-drawer &, .ant-popover & {
    right: 16px;
  }
}

.ant-select-multiple {
  .ant-select-selector {
    padding: 4px 6px 4px 24px;
  }

  .ant-select-selection-item {
    border: none;
    border-radius: 100px;
    background-color: $c_light_blue;
    color: $c_primary;
    font-size: 14px;
    line-height: 16px;
    padding: 5px 16px;
    margin: 4px 14px 4px 0;
    height: 27px;
    display: inline-flex;
    align-items: center;

    &-content {
      margin-right: 14px;
    }

    &-remove {
      font-size: 12px;
      line-height: 12px;
      svg path {
        stroke: $c_primary;
      }
      
    }
  }

  .ant-select-selection-placeholder {
    left: 24px;
    right: 24px;
  }

  .ant-select-selection-search:first-child {
    margin-left: 18px;

    .ant-drawer &, .ant-popover & {
      margin-left: 7px;
    }

    .ant-select-selection-search-input {
      margin-left: 0;
    }
  }
}

.ant-select-single {
  &.ant-select-show-arrow .ant-select-selection-search {
    left: 24px;
    right: 24px;

    .ant-drawer &, .ant-popover & {
      left: 16px;
      right: 16px;
    }
  }

  &:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    min-height: 45px;

    .ant-drawer &, .ant-popover & {
      min-height: 35px;
    }
  }

  .ant-select-arrow {
    right: 24px;
    top: 50%;
    margin-top: -8px;

    .ant-drawer &, .ant-popover & {
      right: 16px;
    }
  }

  .ant-select-clear {
    right: 16px;
    margin-top: -8px;
  }

  &.ant-select-show-arrow .ant-select-clear {
    right: 56px;

    .ant-drawer &, .ant-popover & {
      right: 16px;
    }
  }

  &.select-autocomplete {
    .ant-select-selection-search {
      left: 16px;
      right: 16px;
    }

    &:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      min-height: 35px;
    }

    /*&.ant-select-single .ant-select-selector {
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        line-height: 22px;
      }
    }*/
  }

  .ant-select-selection-item, .ant-select-selection-placeholder {
    .ant-drawer &, .ant-popover & {
      line-height: 22px;
    }
  }
}

.ant-select-dropdown {
  .ant-select-item-option-content {
    color: $c_text;
  }
}
