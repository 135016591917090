@import '~@yougig/ui/styles/variables';

.apply-button {
  text-align: center;
}

.apply-notification {
  background-color: #F6F7FB;
  border-radius: 10px;
  text-align: center;
  padding: 32px 20px;

  h2.apply-notification__title{
    margin: 0 0 16px;
    font-size: 32px;
    font-weight: 400;
    color: $c_text;
  }

  &__text {
    font-size: 18px;
    color: $c_text;
  }

  &__cancel-button {
    margin-top: 16px;
    button {
      font-size: 12px;
      color: $c_primary;
      border-color: $c_primary;
      padding: 3px 9px;
      min-width: 122px;
      border-radius: 5px;

      &:hover {
        color: $c_persian_blue;
        border-color: $c_persian_blue;
      }
    }
  }
}

.apply-drawer {
  h2.ant-typography {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
  }

  .ant-btn {
    margin-top: 24px;
  }

  .team {
    border: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 40px;
  }

  a, label {
    font-size: 18px;
    line-height: 24px;
  }

  a {
    margin-bottom: 24px;
    display: inline-block;
    svg {
      vertical-align: top;
      margin: 4px 0 4px 8px;
    }
  }

  label {
    .ant-radio,
    .ant-checkbox {
      top: 2px;
    }
  }

  .ant-space {
    gap: 24px;

    &-item {
      margin: 0;
    }
  }

  .ant-checkbox-wrapper,
  .ant-radio-wrapper {
    margin: 0;
  }
}
